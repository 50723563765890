<template>
	<div>
		<b-modal
			hide-footer
			id="power-machine-modal"
			title="Confirm Action"
			centered
		>
			<p v-if="machine">
				Are you sure you want to switch the power state of
				<b>"{{ machine.name }}"</b>?
			</p>
			<br />
			<div class="text-right">
				<b-button
					@click="() => $bvModal.hide('power-machine-modal')"
					variant="outline-dark"
					class="mr-2"
					>Cancel</b-button
				>
				<b-button @click="toggleMachine" variant="success">Confirm</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { mapActions } from "vuex";
	export default {
		props: {
			machine: Object,
		},
		data() {
			return {};
		},
		methods: {
			...mapActions("gameboxModule", ["togglePower"]),
			async toggleMachine() {
				try {
					const data = await this.togglePower(this.machine.serialNumber);
					//Show success message to user
					this.$bvToast.toast(`Power state updated for ${this.machine.name}`, {
						title: "Machine Updated",
						autoHideDelay: 7000,
						variant: "success",
					});
					this.$bvModal.hide("power-machine-modal");
				} catch (error) {
					return this.$bvToast.toast(error.response.data, {
						title: "Error",
						autoHideDelay: 7000,
						variant: "danger",
					});
				}
			},
		},
	};
</script>

<style></style>
