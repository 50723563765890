<template>
  <b-modal
    ref="modal"
    v-model="isOpen"
    :title="modalMode == 'add' ? 'Create New Game' : 'Edit Game'"
    title-class="font-18"
    body-class="p-4"
    no-close-on-esc
    no-close-on-backdrop
    @ok="handleSubmission"
    @cancel="handleCancel"
    @close="resetForm"
  >
    <form class="px-3" action="#">
      <b-alert
        :variant="notification.type"
        class="mt-3"
        v-if="notification.message"
        :show="5"
        dismissible
        @dismissed="notification.message = ''"
        >{{ notification.message }}</b-alert
      >

      <div class="form-group">
        <label for="username">Name</label>
        <input
        v-model="$v.formData.name.$model"
          class="form-control"
          :class="{ 'is-invalid': !$v.formData.name.required }"
          type="text"
        />
        <div class="invalid-feedback" v-if="!$v.formData.name.required">
          <span v-if="!$v.formData.name.required">Name is required</span>
        </div>
      </div>

      <div class="form-group">
        <label for="pulseRate">Pulse Rate</label>
        <input
          v-model="$v.formData.pulseRate.$model"
          class="form-control"
          :class=" { 'is-invalid': !$v.formData.pulseRate.required || !$v.formData.pulseRate.numeric }"
          type="number"
        />
        <div class="invalid-feedback" v-if="!$v.formData.pulseRate.required || !$v.formData.pulseRate.numeric">
          <span v-if="!$v.formData.pulseRate.required">Pulse rate is required</span>
          <span v-if="!$v.formData.pulseRate.numeric">Pulse rate must be a number</span>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group no-margin">
            <label for="field-7" class="control-label"
              >Description</label
            >
            <textarea
              v-model="formData.description"
              class="form-control"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              v-model="isActive"
              type="checkbox"
              class="custom-control-input"
              id="customCheck1"
            />
            <label class="custom-control-label" for="customCheck1"> Is this game active upon creation?</label>
        </div>
      </div>
    </form> 
  </b-modal>
</template>

<script>
  import { required, numeric } from "vuelidate/lib/validators";
import { mapActions } from 'vuex';

  export default {
    data() {
      return {
        modalMode: "add",
        isOpen: false,
        formData: {
          name: "",
          pulseRate: "",
          description: "",
        },
        isActive: true,
        notification: {
          message: "",
          type: ""
        }
      }
    },

    validations: {
      formData: {
        name: {
          required
        },

        pulseRate: {
          required,
          numeric
        },
      }
    },

    methods: {
      ...mapActions('gameModule', ['createGame', 'updateGame']),

      openModal(item) {
        if(item != null) {
          this.formData = {...item}
          this.modalMode = "edit"
        } else {
          this.modalMode = "add"
        }
        this.$refs['modal'].show();
      },

      handleCancel() {
        this.resetForm();
      },

      async handleSubmission(event) {
        event.preventDefault();

        this.$v.$touch();

        if(!this.$v.$invalid) {
          let result ={}
          this.formData.isActive = this.isActive;
          this.formData.pulseRate = Number(this.formData.pulseRate);
          if(this.modalMode == "add")
            result = await this.createGame(this.formData);
          else if(this.modalMode == "edit")
            result = await this.updateGame(this.formData);
          if(result.status == 201 || result.status == 200) {
            if(result.status == 200) {
              this.$bvToast.toast(`Game Successfully Edited`, {
                title: `Success!`,
                variant: 'success',
                solid: true
              });
            } else {
              this.$bvToast.toast(`Game Successfully Created and Added`, {
                title: `Success!`,
                variant: 'success',
                solid: true
              });  
            }

            this.resetForm();
            this.$nextTick(() => {
              this.$refs['modal'].hide()
            });
          } else {
            this.notification.message = Array.isArray(result.message) ? result.message[0] : result.message
            this.notification.type = "danger"
          }
         
        } else {
          this.notification.message = "Please complete all the fields before adding"
          this.notification.type = "danger"
        }
      },

      resetForm() {
        this.formData = {
          name: "",
          pulseRate: "",
          description: ""
        }
        this.isActive = true;
        this.notification.message = ''
      }
    }
  }
</script>

<style scoped>

</style>