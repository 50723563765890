<template>
	<b-modal
		ref="modal"
		v-model="isOpen"
		:title="modalMode == 'add' ? 'Create New Game Machine' : 'Edit Game Machine'"
		title-class="font-18"
		body-class="p-4"
		no-close-on-esc
		no-close-on-backdrop
		@ok="handleSubmission"
		@cancel="handleCancel"
		@close="resetForm"
	>
		<form class="px-3" action="#">
			<b-alert
				:variant="notification.type"
				class="mt-3"
				v-if="notification.message"
				:show="5"
				dismissible
				@dismissed="notification.message = ''"
				>{{ notification.message }}</b-alert
			>
			<div class="form-group" :class="{ 'form-group--error': $v.formData.name.$error }">
				<label for="username">Name</label>
				<input
					v-model="$v.formData.name.$model"
					class="form-control"
					:class="{ 'is-invalid': !$v.formData.name.required }"
					type="text"
					id="username"
				/>
				<div v-if="!$v.formData.name.required" class="invalid-feedback">
					<span v-if="!$v.formData.name.required">Name is required.</span>
				</div>
			</div>

			<div class="form-group">
				<label for="securityKey">Security Key</label>
				<input v-model="formData.securityKey" class="form-control" type="text" id="securityKey" />
			</div>

			<div class="form-group">
				<label for="serialNumber">Serial Number</label>
				<input
					v-model="$v.formData.serialNumber.$model"
					class="form-control"
					type="text"
					id="serialNumber"
					:class="{
						'is-invalid': !$v.formData.serialNumber.required || !$v.formData.serialNumber.alphaNum,
					}"
				/>
				<div
					class="invalid-feedback"
					v-if="!$v.formData.serialNumber.required || !$v.formData.serialNumber.alphaNum"
				>
					<span v-if="!$v.formData.serialNumber.required">Serial Number is required</span>
					<span v-if="!$v.formData.serialNumber.alphaNum"
						>Only letters and Numbers are allowed</span
					>
				</div>
			</div>

			<div class="form-group">
				<label for="role">Select Game</label>
				<select
					class="form-control"
					:class="{ 'is-invalid': !$v.formData.games.required }"
					name="role"
					id="role"
					v-model="$v.formData.games.$model"
				>
					<template v-for="(game, index) in games">
						<option :key="index" :value="game._id">{{ game.name }}</option>
					</template>
				</select>
				<div v-if="!$v.formData.games.required" class="invalid-feedback">
					<span v-if="!$v.formData.games.required">Please select a game.</span>
				</div>
			</div>

			<div class="form-group">
				<label for="role">Select Branch</label>
				<select
					class="form-control"
					:class="{ 'is-invalid': !$v.formData.branch.required }"
					name="role"
					id="role"
					v-model="$v.formData.branch.$model"
				>
					<template v-for="(branch, index) in branches">
						<option :key="index" :value="branch._id">{{ branch.name }}</option>
					</template>
				</select>
				<div v-if="!$v.formData.branch.required" class="invalid-feedback">
					<span v-if="!$v.formData.branch.required">Please select a branch.</span>
				</div>
			</div>
			<b-form-group label="IOT Enabled">
				<b-form-checkbox v-model="formData.iotEnabled"
					>This machine is IOT enabled.</b-form-checkbox
				>
			</b-form-group>
			<b-form-group label="Location Order" description="The position of this machine on site.">
				<b-form-input v-model.number="formData.locationOrder" min="1" type="number"></b-form-input>
			</b-form-group>
			<div class="row">
				<div class="col-md-12">
					<div class="form-group no-margin">
						<label for="field-7" class="control-label">Description</label>
						<textarea v-model="formData.description" class="form-control"></textarea>
					</div>
				</div>
			</div>
		</form>
	</b-modal>
</template>

<script>
	import { required, alphaNum } from "vuelidate/lib/validators";
	import { mapActions, mapState } from "vuex";

	export default {
		data() {
			return {
				isOpen: false,
				modalMode: "add",
				formData: {
					name: "",
					securityKey: "",
					serialNumber: "",
					games: "",
					branch: "",
					description: "",
					readingOut: "",
					readingIn: "",
					iotEnabled: false,
					locationOrder: null,
				},
				isActive: true,

				notification: {
					message: "",
					type: "",
				},
			};
		},

		validations: {
			formData: {
				name: {
					required,
				},
				serialNumber: {
					required,
					alphaNum,
				},
				branch: {
					required,
				},
				games: {
					required,
				},
			},
		},

		computed: {
			...mapState("gameModule", ["games"]),
			...mapState("branchModule", ["branches"]),
		},

		methods: {
			...mapActions("gameboxModule", ["createGamebox", "updateGamebox"]),

			openModal(item) {
				if (item != null) {
					this.formData = { ...item };
					this.formData.securityKey = item.securityKey || "";
					this.formData.branch = this.formData.branch._id;
					this.formData.games = item.games[0]._id;
					this.formData.iotEnabled = item.iotEnabled;
					this.formData.locationOrder = item.locationOrder;
					this.modalMode = "edit";
				} else {
					this.modalMode = "add";
				}
				this.$refs["modal"].show();
			},

			async handleSubmission(event) {
				event.preventDefault();
				this.$v.$touch();
				this.formData.games = [this.formData.games];
				this.formData.isActive = this.isActive;
				if (!this.$v.$invalid) {
					let result = {};
					if (this.modalMode == "add") result = await this.createGamebox(this.formData);
					else result = await this.updateGamebox(this.formData);
					if (result.status == 201 || result.status == 200) {
						if (result.status == 200) {
							this.$bvToast.toast(`Game Machine Successfully Edited`, {
								title: `Success!`,
								variant: "success",
								solid: true,
							});
						} else {
							this.$bvToast.toast(`Game Machine Successfully Creadted and Added`, {
								title: `Success!`,
								variant: "success",
								solid: true,
							});
						}

						this.resetForm();
						this.$emit("refresh");
						this.$nextTick(() => {
							this.$refs["modal"].hide();
						});
					} else {
						this.notification.message = result.message;
						this.notification.type = "danger";
					}
				} else {
					this.notification.message = "Please complete all the fields before adding";
					this.notification.type = "danger";
				}
			},

			handleCancel() {
				this.resetForm();
			},

			resetForm() {
				this.formData = {
					name: "",
					securityKey: "",
					serialNumber: "",
					games: "",
					branch: "",
					description: "",
					isActive: true,
					powerState: 1,
				};
				this.notification.message = "";
			},
		},
	};
</script>

<style scoped></style>
