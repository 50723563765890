<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<GameBoxModal @refresh="getGameboxes()" ref="gameBoxModal" />
		<GameModal ref="gameModal" />
		<UserModal ref="userModal" />
		<BranchModal ref="branchModal" />
		<UploadModal ref="uploadModal" />
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="text-center">
							<div class="row">
								<div class="col-md-4 col-xl-3">
									<div class="py-1">
										<h3 class="text-success">{{ totalUsers }}</h3>
										<p class="text-uppercase mb-1 font-13 font-weight-bold">Total Users</p>
									</div>
								</div>

								<div class="col-md-4 col-xl-3">
									<div class="py-1">
										<h3 class="text-secondary">{{ totalBranches }}</h3>
										<p class="text-uppercase mb-1 font-13 font-weight-bold">Total Branches</p>
									</div>
								</div>

								<div class="col-md-4 col-xl-3">
									<div class="py-1">
										<h3>{{ totalGames }}</h3>
										<p class="text-uppercase mb-1 font-13 font-weight-bold">Total Games</p>
									</div>
								</div>

								<div class="col-md-4 col-xl-3">
									<div class="py-1">
										<h3 class="text-warning">{{ totalGameboxes }}</h3>
										<p class="text-uppercase mb-1 font-13 font-weight-bold">Total Machines</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-xl-12">
				<div class="card">
					<div class="row">
						<div class="col-md-12">
							<div class="col text-right">
								<b-button variant="primary" class="ml-1 mt-2 mb-2" @click="openUploadModal">
									Bulk Upload Transactions</b-button
								>
							</div>
						</div>
					</div>
					<div class="card-body pb-1">
						<b-card no-body class="shadow-none border-0">
							<b-tabs justified nav-class="nav-tabs nav-bordered">
								<b-tab title="Users">
									<b-card class="pt-4">
										<b-row>
											<b-col>
												<h4 class="header-title">System Users</h4>
											</b-col>
											<b-col class="text-right">
												<b-button @click="downloadUsers" variant="outline-primary" class="mr-3"
													>Export Users</b-button
												>
												<b-button @click="openAddUserModal(null)" variant="success"
													>Add user</b-button
												>
											</b-col>
										</b-row>
										<br />
										<b-table
											thead-class="red-t-head"
											outlined
											:per-page="perPageUser"
											:current-page="currentPageUser"
											hover
											:fields="tableHeadUsers"
											:items="users"
										>
											<template #cell(actions)="row">
												<span role="button" class="mr-3" @click="openAddUserModal(row.item)">
													<i class="mdi mdi-18px mdi-account-edit"></i>
												</span>
												<span role="button" @click="deleteItem(row.item, 'user')">
													<i class="mdi mdi-18px mdi-delete"></i>
												</span>
											</template>
										</b-table>
										<b-pagination
											:total-rows="users && users.length"
											:per-page="perPageUser"
											v-model="currentPageUser"
										></b-pagination>
									</b-card>
								</b-tab>
								<b-tab title="Branches">
									<b-card class="pt-4">
										<b-row>
											<b-col>
												<h4 class="header-title">System Branches</h4>
											</b-col>
											<b-col class="text-right">
												<b-button @click="downloadBranches" variant="outline-primary" class="mr-3"
													>Export Branches</b-button
												>
												<b-button @click="openAddBranchModal(null)" variant="success"
													>Add Branch</b-button
												>
											</b-col>
										</b-row>
										<br />
										<b-table
											thead-class="red-t-head"
											outlined
											:per-page="perPageBranch"
											:current-page="currentPageBranch"
											hover
											:fields="tableHeadBranches"
											:items="branches"
										>
											<template #cell(actions)="row">
												<span role="button" class="mr-3" @click="openAddBranchModal(row.item)">
													<i class="mdi mdi-18px mdi-pencil"></i>
												</span>
												<span role="button" @click="deleteItem(row.item, 'branch')">
													<i class="mdi mdi-18px mdi-delete"></i>
												</span>
											</template>
										</b-table>
										<b-pagination
											:total-rows="branches && branches.length"
											:per-page="perPageBranch"
											v-model="currentPageBranch"
										></b-pagination>
									</b-card>
								</b-tab>
								<b-tab title="Games">
									<b-card class="pt-4">
										<b-row>
											<b-col>
												<h4 class="header-title">Games</h4>
											</b-col>
											<b-col class="text-right">
												<b-button @click="downloadGames" variant="outline-primary" class="mr-3"
													>Export Games</b-button
												>
												<b-button @click="openAddGamesModal(null)" variant="success"
													>Add Game</b-button
												>
											</b-col>
										</b-row>
										<br />
										<b-table
											thead-class="red-t-head"
											outlined
											:per-page="perPageGame"
											:current-page="currentPageGame"
											hover
											:fields="tableHeadGames"
											:items="games"
										>
											<template #cell(actions)="row">
												<span role="button" class="mr-3" @click="openAddGamesModal(row.item)">
													<i class="mdi mdi-18px mdi-pencil"></i>
												</span>
												<span role="button" @click="deleteItem(row.item, 'game')">
													<i class="mdi mdi-18px mdi-delete"></i>
												</span>
											</template>
										</b-table>
										<b-pagination
											:total-rows="games && games.length"
											:per-page="perPageGame"
											v-model="currentPageGame"
										></b-pagination>
									</b-card>
								</b-tab>
								<b-tab title="Machines" active>
									<b-card class="pt-4">
										<b-row class="mb-3">
											<b-col>
												<h4 class="header-title">Machines</h4>
											</b-col>
											<b-col class="text-right">
												<b-button @click="downloadMachines" variant="outline-primary" class="mr-3"
													>Export Machines</b-button
												>
												<b-button @click="openAddGameBoxModal(null)" variant="success"
													>Add Machine</b-button
												>
											</b-col>
										</b-row>
										<b-input placeholder="Search here..." v-model="searchBoxes" />
										<br />

										<b-table
											thead-class="red-t-head"
											outlined
											:filter="searchBoxes"
											:per-page="perPageGamebox"
											:current-page="currentPageGamebox"
											hover
											:fields="tableHeadGameboxes"
											:items="gameboxes"
										>
											<template #cell(power)="row">
												<span role="button" class="mr-3" @click="openPowerModal(row.item)">
													<i class="mdi mdi-18px mdi-power-settings"></i>
												</span>
											</template>
											<template #cell(actions)="row">
												<span role="button" class="mr-3" @click="openAddGameBoxModal(row.item)">
													<i class="mdi mdi-18px mdi-pencil"></i>
												</span>
												<span role="button" @click="deleteItem(row.item, 'machine')">
													<i class="mdi mdi-18px mdi-delete"></i>
												</span>
											</template>
										</b-table>
										<b-pagination
											:total-rows="gameboxes && gameboxes.length"
											:per-page="perPageGamebox"
											v-model="currentPageGamebox"
										></b-pagination>
									</b-card>
								</b-tab>
							</b-tabs>
						</b-card>
					</div>
				</div>
			</div>
		</div>
		<PowerModal :machine="selectedMachine" />
	</Layout>
</template>

<script>
	import Layout from "../../../layouts/vertical";
	import PageHeader from "@/components/Page-header";
	import Table from "@/components/tables/BasicTable";
	import { mapState, mapActions, mapGetters } from "vuex";
	import GameBoxModal from "../../../../components/modals/AddGameBoxModal.vue";
	import GameModal from "../../../../components/modals/AddGameModal.vue";
	import UserModal from "../../../../components/modals/AddUserModal.vue";
	import BranchModal from "../../../../components/modals/AddBranchModal.vue";
	import UploadModal from "../../../../components/modals/UploadTransactionModal.vue";
	import PowerModal from "@/components/modals/PowerModal.vue";

	import CountriesMixin from "../../../../mixins/countries";

	export default {
		mixins: [CountriesMixin],

		components: {
			BranchModal,
			GameBoxModal,
			GameModal,
			UserModal,
			Layout,
			PageHeader,
			Table,
			UploadModal,
			PowerModal,
		},
		page: {
			title: "Settings",
		},
		data() {
			return {
				title: "Settings",
				items: [
					{
						text: "General Settings",
						active: true,
					},
				],
				selectedMachine: null,
				//Vars for user table
				currentPageUser: 1,
				perPageUser: 7,
				tableHeadUsers: [
					{
						label: "Name",
						key: "name",
						sortable: true,
					},
					{
						label: "Email",
						key: "email",
						sortable: true,
					},
					{
						label: "Role",
						key: "role",
						sortable: true,
					},
					{
						label: "Account Status",
						key: "isActive",
					},
					{
						label: "Action",
						key: "actions",
					},
				],
				//Vars for branches table
				currentPageBranch: 1,
				perPageBranch: 7,
				tableHeadBranches: [
					{
						label: "Code",
						key: "code",
						sortable: true,
					},

					{
						label: "Name",
						key: "name",
						sortable: true,
					},
					{
						label: "Commission",
						key: "commissionPercentage",
						type: "commission",
						sortable: true,
					},
					{
						label: "Float",
						key: "locationFloat",
						type: "money",
						sortable: true,
					},
					{
						label: "Assigned Manager",
						key: "manager.name",
						sortable: true,
					},

					{
						label: "Status",
						key: "isActive",
					},
					{
						label: "Action",
						key: "actions",
						type: ["edit", "delete"],
					},
				],
				//Vars for games table
				currentPageGame: 1,
				perPageGame: 7,
				tableHeadGames: [
					{
						label: "Game Name",
						key: "name",
						sortable: true,
					},
					{
						label: "Description",
						key: "description",
						sortable: true,
					},
					{
						label: "Pulse Rate",
						key: "pulseRate",
						sortable: true,
					},
					{
						label: "status",
						key: "isActive",
						sortable: true,
					},
					{
						label: "Action",
						key: "actions",
					},
				],
				//Vars for game boxes table
				currentPageGamebox: 1,
				perPageGamebox: 12,
				searchBoxes: "",
				tableHeadGameboxes: [
					{
						label: "Power",
						key: "power",
					},
					{
						label: "Serial Number",
						key: "serialNumber",
						sortable: true,
					},
					{
						label: "Name",
						key: "name",
						sortable: true,
					},
					{
						label: "Game Type",
						key: "games",
						formatter: (v) => {
							return v[0]?.name;
						},
					},
					{
						label: "Branch Located",
						key: "branch.name",
						sortable: true,
					},
					{
						label: "Action",
						key: "actions",
						type: ["edit", "delete"],
					},
				],
				pagination: {
					search: "",
					paginated: "",
					skip: "",
					limit: "",
				},
			};
		},
		computed: {
			...mapState("branchModule", ["branches", "totalBranches"]),
			...mapState("userModule", ["users", "totalUsers"]),
			...mapGetters("userModule", ["getUserById"]),
			...mapState("gameModule", ["games", "totalGames"]),
			...mapState("gameboxModule", ["gameboxes", "totalGameboxes"]),
		},

		created() {
			this.loadCountries();
		},

		beforeMount() {
			this.getUsers();
			this.getGames();
			this.getGameboxes();
			this.getBranches();
		},

		methods: {
			...mapActions("userModule", ["getUsers", "deleteUser", "exportUsers"]),
			...mapActions("gameModule", ["getGames", "deleteGame", "exportGames"]),
			...mapActions("gameboxModule", ["getGameboxes", "deleteGamebox", "exportMachines"]),
			...mapActions("branchModule", ["getBranches", "deleteBranch", "exportBranch"]),
			openPowerModal(machine) {
				this.selectedMachine = machine;
				this.$bvModal.show("power-machine-modal");
			},

			// Method used to download branch data
			async downloadBranches() {
				try {
					await this.exportBranch();
				} catch (error) {
					console.log(error);
				}
			},
			// Method used to download user data
			async downloadUsers() {
				try {
					await this.exportUsers();
				} catch (error) {
					console.log(error);
				}
			},
			// Method used to download games data
			async downloadGames() {
				try {
					await this.exportGames();
				} catch (error) {
					console.log(error);
				}
			},
			// Method used to download machines data
			async downloadMachines() {
				try {
					await this.exportMachines();
				} catch (error) {
					console.log(error);
				}
			},

			openAddGameBoxModal(item = null) {
				this.$refs["gameBoxModal"].openModal(item);
			},

			openAddGamesModal(item = null) {
				this.$refs["gameModal"].openModal(item);
			},

			openAddUserModal(item = null) {
				this.$refs["userModal"].openModal(item);
			},

			openAddBranchModal(item = null) {
				this.$refs["branchModal"].openModal(item);
			},

			openUploadModal() {
				this.$refs["uploadModal"].openModal();
			},

			async deleteItem(item, setting) {
				let result = {};
				let answer = window.confirm("Are you sure you want to delete?");
				if (!answer) return;

				if (setting == "user") result = await this.deleteUser({ userId: item._id });
				else if (setting == "branch") result = await this.deleteBranch({ branchId: item._id });
				else if (setting == "game") result = await this.deleteGame({ gameId: item._id });
				else if (setting == "machine") result = await this.deleteGamebox({ gameboxId: item._id });

				if (result.status == 204) {
					this.$bvToast.toast(`${setting} "${item.name}" was deleted successfully`, {
						title: `Deleted!`,
						variant: "success",
						solid: true,
					});
				}
			},
		},
	};
</script>
