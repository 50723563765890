<template>
	<b-modal
		ref="modal"
		v-model="isOpen"
		:title="modalMode == 'add' ? 'Create New Branch' : 'Edit Branch'"
		title-class="font-18"
		body-class="p-4"
		no-close-on-esc
		no-close-on-backdrop
		@ok="handleSubmission"
		@cancel="handleCancel"
		@close="resetForm"
		size="lg"
	>
		<form class="px-3" action="#">
			<b-alert
				:variant="notification.type"
				class="mt-3"
				v-if="notification.message"
				:show="5"
				dismissible
				@dismissed="notification.message = ''"
				>{{ notification.message }}</b-alert
			>
			<div class="form-group">
				<label for="username">Name</label>
				<input
					v-model="$v.formData.name.$model"
					class="form-control"
					:class="{ 'is-invalid': !$v.formData.name.required }"
					type="text"
					id="username"
				/>
				<div v-if="!$v.formData.name.required" class="invalid-feedback">
					<span v-if="!$v.formData.name.required">Name is required.</span>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label for="code">Code</label>
						<input
							v-model="$v.formData.code.$model"
							class="form-control"
							:class="{ 'is-invalid': !$v.formData.code.required }"
							type="text"
						/>
						<div v-if="!$v.formData.code.required" class="invalid-feedback">
							<span v-if="!$v.formData.code.required">Code is required.</span>
						</div>
					</div>
				</div>

				<div class="col-md-6">
					<div class="form-group">
						<label for="locationManager">Location Manager</label>
						<select
							v-model="$v.formData.manager.$model"
							class="form-control"
							:class="{ 'is-invalid': !$v.formData.manager.required }"
							name="locationManager"
						>
							<template
								v-for="(manager, index) in getUserByRole('BRANCH-MANAGER')"
							>
								<option :key="index" :value="manager._id">
									{{ manager.name }}
								</option>
							</template>
						</select>
						<div v-if="!$v.formData.manager.required" class="invalid-feedback">
							<span v-if="!$v.formData.manager.required"
								>Location manager is required.</span
							>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<!-- Commission Percentage -->
				<div class="col-md-6">
					<div class="form-group">
						<label for="commissionPercentage">Commission Percentage</label>
						<b-input-group append="%">
							<input
								v-model="$v.formData.commissionPercentage.$model"
								class="form-control"
								:class="{
									'is-invalid':
										!$v.formData.commissionPercentage.required ||
										!$v.formData.commissionPercentage.minValue ||
										!$v.formData.commissionPercentage.numeric,
								}"
								type="number"
							/>
						</b-input-group>
						<div
							v-if="
								!$v.formData.commissionPercentage.required ||
								!$v.formData.commissionPercentage.minValue ||
								!$v.formData.commissionPercentage.numeric
							"
							class="invalid-feedback"
						>
							<span v-if="!$v.formData.commissionPercentage.required"
								>Commission percentage is required.</span
							>
							<span v-if="!$v.formData.commissionPercentage.minValue"
								>Commission percentage must be higher than 0.</span
							>
							<span v-if="!$v.formData.commissionPercentage.numeric"
								>Commission percentage must be numeric.</span
							>
						</div>
					</div>
				</div>

				<!----- Location Float---->
				<div class="col-md-6">
					<div class="form-group">
						<label for="locationFloat">Float Amount</label>
						<b-input-group prepend="$">
							<input
								v-model="$v.formData.locationFloat.$model"
								class="form-control"
								:class="{
									'is-invalid':
										!$v.formData.locationFloat.required ||
										!$v.formData.locationFloat.minValue ||
										!$v.formData.locationFloat.numeric,
								}"
								type="number"
							/>
						</b-input-group>
						<div
							v-if="
								!$v.formData.locationFloat.required ||
								!$v.formData.locationFloat.minValue ||
								!$v.formData.locationFloat.numeric
							"
							class="invalid-feedback"
						>
							<span v-if="!$v.formData.locationFloat.required"
								>Location Float is required.</span
							>
							<span v-if="!$v.formData.locationFloat.minValue"
								>Location Float must be greater than 0.</span
							>
							<span v-if="!$v.formData.locationFloat.numeric"
								>Location Float must be numeric.</span
							>
						</div>
					</div>
				</div>
			</div>

			<!-- Jackpot for branch -->
			<div class="row">
				<!----- Location Float---->
				<div class="col-md-6">
					<div class="form-group">
						<label for="jackpotAmount">Jackpot Amount</label>
						<b-input-group prepend="$">
							<input
								id="jackpotAmount"
								name="jackpotAmount"
								v-model="formData.jackpotAmount"
								class="form-control"
								type="number"
							/>
						</b-input-group>
					</div>
				</div>

				<div class="col-md-6">
					<div class="form-group">
						<label for="jackpotPercentage">Jackpot Percentage</label>
						<b-input-group append="%">
							<input
								id="jackpotPercentage"
								name="jackpotPercentage"
								v-model="formData.jackpotPercentage"
								class="form-control"
								type="number"
							/>
						</b-input-group>
					</div>
				</div>
			</div>
			<!----- Street --------->
			<div class="form-group">
				<label for="street">Street</label>
				<input
					v-model="$v.formData.street.$model"
					class="form-control"
					:class="{ 'is-invalid': !$v.formData.street.required }"
					type="text"
					id="username"
				/>
				<div v-if="!$v.formData.street.required" class="invalid-feedback">
					<span v-if="!$v.formData.street.required">Street is required.</span>
				</div>
			</div>

			<div class="row">
				<!-- Country -->
				<div class="col-md-4">
					<div class="form-group">
						<label for="username">Country</label>
						<select
							class="form-control"
							v-model="$v.formData.country.$model"
							:class="{ 'is-invalid': !$v.formData.country.required }"
						>
							<template v-for="(country, index) in countries">
								<option :key="index" :value="country.name">
									{{ country.name }}
								</option>
							</template>
						</select>
						<div v-if="!$v.formData.country.required" class="invalid-feedback">
							<span v-if="!$v.formData.country.required"
								>Country is required.</span
							>
						</div>
					</div>
				</div>

				<!-- Province -->
				<div class="col-md-4">
					<div class="form-group">
						<label for="username">Province</label>
						<select
							class="form-control"
							:class="{ 'is-invalid': !$v.formData.province.required }"
							v-model="$v.formData.province.$model"
						>
							<template v-for="(province, index) in provinces">
								<option :key="index" :value="province">{{ province }}</option>
							</template>
						</select>
						<div v-if="!$v.formData.province.required" class="invalid-feedback">
							<span v-if="!$v.formData.province.required"
								>Province is required.</span
							>
						</div>
					</div>
				</div>

				<!-- City -->
				<div class="col-md-4">
					<div class="form-group">
						<label for="city">City</label>
						<input
							v-model="$v.formData.city.$model"
							class="form-control"
							:class="{ 'is-invalid': !$v.formData.city.required }"
							type="text"
							id="city"
						/>
						<div v-if="!$v.formData.city.required" class="invalid-feedback">
							<span v-if="!$v.formData.city.required">City is required.</span>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="custom-control custom-checkbox">
					<input
						v-model="isActive"
						type="checkbox"
						class="custom-control-input"
						id="customCheck1"
						checked
					/>
					<label class="custom-control-label" for="customCheck1">
						Is branch currently active?</label
					>
				</div>
			</div>
		</form>
	</b-modal>
</template>

<script>
	import { required, numeric, minValue } from "vuelidate/lib/validators";
	import { mapActions, mapGetters } from "vuex";
	import CountriesMixin from "../../mixins/countries";

	export default {
		mixins: [CountriesMixin],

		data() {
			return {
				modalMode: "add",
				isOpen: false,
				formData: {
					name: "",
					code: "",
					street: "",
					city: "",
					province: "",
					jackpotAmount: 0,
					jackpotPercentage: 0,
					country: "Jamaica",
					latitude: 5,
					longitude: 77,
					manager: "",
					commissionPercentage: "0",
					locationFloat: "0",
				},
				isActive: true,

				notification: {
					message: "",
					type: "",
				},
			};
		},

		async beforeMount() {
			if (this.countries.length < 1) await this.loadCountries();

			this.getProvinces(this.formData.country);
		},

		validations: {
			formData: {
				name: {
					required,
				},

				code: {
					required,
				},

				street: {
					required,
				},

				country: {
					required,
				},

				province: {
					required,
				},

				city: {
					required,
				},

				manager: {
					required,
				},

				commissionPercentage: {
					required,
					numeric,
					minValue: (value) => value > 0,
				},

				locationFloat: {
					required,
					numeric,
					minValue: (value) => value > 0,
				},
			},
		},

		computed: {
			...mapGetters("userModule", ["getUserByRole"]),
		},

		methods: {
			...mapActions("branchModule", ["createBranch", "updateBranch"]),

			openModal(item) {
				if (item != null) {
					this.formData = { ...item };
					this.modalMode = "edit";
				} else {
					this.modalMode = "add";
				}
				this.$refs["modal"].show();
			},

			async handleSubmission(event) {
				event.preventDefault();
				this.$v.$touch();
				this.formData.locationFloat =
					Number.parseInt(this.formData.locationFloat) || 0;
				this.formData.jackpotAmount =
					Number.parseFloat(this.formData.jackpotAmount) || 0;
				this.formData.jackpotPercentage =
					Number.parseFloat(this.formData.jackpotPercentage) || 0;
				this.formData.commissionPercentage =
					Number(this.formData.commissionPercentage) || 0;
				this.formData.isActive = this.isActive;

				if (!this.$v.$invalid) {
					this.formData.longitude = 77;
					this.formData.latitude = 5;
					let result = {};
					if (this.modalMode == "add") {
						result = await this.createBranch(this.formData);
					} else if (this.modalMode == "edit") {
						result = await this.updateBranch(this.formData);
					}
					if (result.status == 201 || result.status == 200) {
						if (result.status == 201) {
							this.$bvToast.toast(`Branch Successfully Created and Added`, {
								title: `Success!`,
								variant: "success",
								solid: true,
							});
						} else {
							this.$bvToast.toast(`Branch Successfully Edited`, {
								title: `Success!`,
								variant: "success",
								solid: true,
							});
						}
						this.resetForm();
						this.$nextTick(() => {
							this.$refs["modal"].hide();
						});
					} else {
						this.notification.message = result.message;
						this.notification.type = "danger";
					}
				} else {
					this.notification.message =
						"Please complete all the fields before adding";
					this.notification.type = "danger";
				}
			},

			handleCancel() {
				this.resetForm();
			},

			resetForm() {
				this.formData = {
					name: "",
					code: "",
					street: "",
					city: "",
					province: "",
					jackpotAmount: 0,
					jackpotPercentage: 0,
					country: "Jamaica",
					latitude: "",
					longitude: "",
					manager: "",
					commissionPercentage: "",
					locationFloat: "",
					isActive: true,
				};
				this.notification.message = "";
			},
		},

		watch: {
			"formData.country": function (newVal) {
				if (newVal) {
					this.getProvinces(this.formData.country);
				}
			},
		},
	};
</script>

<style scoped></style>
