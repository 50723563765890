<template>
  <b-modal
  ref="modal"
  @ok.prevent="handleSubmission"
  >
    <div class="card">
      <!-- <div class="card-title">
        <b-alert
          variant="danger"
          class="mt-3"
          :show="120"
          dismissible
          v-for="message in errorMessages"
          :key="message"
          >
           {{ message }}
          </b-alert>
      </div>   -->
      <div class="card-body">
        <h4 class="header-title m-t-0">Upload Transaction CSV</h4>
        <p class="text-muted font-13 m-b-30">
          Please drag and drop or click the cloud icon and select the file you wish to upload.
          Only .csv files will be accepted.
        </p>
        <!-- file upload -->
        <vue-dropzone
          id="dropzone"
          ref="uploader"
          :use-custom-slot="true"
          :options="dropzoneOptions"
        >
          <div class="dz-message needsclick">
            <i class="h1 text-muted ri-upload-cloud-2-line"></i>
            <h3>Drop files here or click to upload.</h3>
            <span class="text-muted font-13"
              >(Only .csv files are accepted)</span
            >
          </div>
        </vue-dropzone>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import vue2Dropzone from "vue2-dropzone";
import { mapActions } from 'vuex';

  export default {
    components: {
      vueDropzone: vue2Dropzone,
    },

    data(){
      return {
        dropzoneOptions: {
          url: 'http://localhost',
          autoProcessQueue: false,
          thumbnailWidth: 150,
          maxFilesize: 1,
          maxFiles: 1,
          headers: {
            "My-Awesome-Header": "header value",
          },
          previewTemplate: this.template(),
        },
        errorMessages: []
      }
    },

    methods:{
      ...mapActions('transactionsModule', ['uploadTransactionCSV']),
      
      openModal() {
        this.$refs['modal'].show()
      },

      template: function () {
      return ` <div class="dropzone-previews mt-3">
            <div class="card mt-1 mb-0 shadow-none border">
                <div class="p-2">
                    <div class="row align-items-center">
                        <div class="col-auto">
                            <img data-dz-thumbnail src="#" class="avatar-sm rounded bg-light" alt="">
                        </div>
                        <div class="col pl-0">
                            <a href="javascript:void(0);" class="text-muted font-weight-bold" data-dz-name></a>
                            <p class="mb-0" data-dz-size></p>
                        </div>
                        <div class="col-auto">
                            <!-- Button -->
                            <a href="" class="btn btn-link btn-lg text-muted" data-dz-remove>
                                <i class="fe-x"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `;
      },

      async handleSubmission(){
        const file = this.$refs['uploader'].getQueuedFiles()[0];
        const formData = new FormData();
        formData.append('file', file);
        const result = await this.uploadTransactionCSV(formData);
        if(result.status == 400) {
          const { message } = result;
          let toastMessage = "";
          this.errorMessages = message;
          
          for (let i = 0; i < message.length; i++) {
            toastMessage += ` ${ message[i] } \n\n ` 
          }

          this.$bvToast.toast(
            `Unable to upload file because of these reasons. Please fix and try uploading again: \n\n ${toastMessage}`, 
          {
            title: `Error!`,
            variant: 'danger',
            solid: true,
            autoHideDelay: 60000
          });
        } else {
          this.$refs.modal.hide();
        }
      }
    }
  }
</script>

<style scoped>
  .b-toast{
    white-space : pre-line
  }
</style>