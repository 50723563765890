<template>
	<div class="card">
		<div class="card-body">
			<div>
				<h4 class="header-title">{{ title }}</h4>
				<p class="sub-header">{{ subtitle }}</p>

				<div class="row">
					<div class="col-2">
						Show
						<select v-model="pagination.perPage" class="px-1 mb-2">
							<option :value="5">5</option>
							<option :value="15">15</option>
							<option :value="20">20</option>
							<option :value="45">45</option>
						</select>
						Entries
					</div>

					<div class="col-10 text-right" v-if="showButton">
						<b-button
							variant="primary"
							class="ml-1 mb-3"
							@click="addUserClick"
							>{{ buttonText }}</b-button
						>
					</div>
				</div>

				<div class="table-responsive">
					<table class="table mb-0">
						<thead style="background-color: #d8060e" class="text-white">
							<tr>
								<th v-for="(header, index) in headers" :key="index">
									{{ header.text }}
								</th>
							</tr>
						</thead>
						<tbody>
							<template v-for="(item, index) in paginatedItems">
								<tr v-if="item" :key="index">
									<td v-for="(header, index) in headers" :key="index">
										{{ getHeaderValue(item, header) }}
										<!--If actions is view-->
										<template v-if="header.value == 'actions'">
											<!--If actions have more than one actions-->
											<div v-if="Array.isArray(header.type)">
												<div class="row">
													<template v-for="(type, index) in header.type">
														<div
															:key="index"
															v-if="type == 'view'"
															class="col-md-4 col-xl-3 col-lg-4 col-sm-6"
														>
															<div @click="view(item)">
																<i class="mdi mdi-18px mdi-eye"></i>
															</div>
														</div>

														<div
															:key="index"
															v-if="type == 'edit'"
															class="col-md-4 col-xl-3 col-lg-4 col-sm-6"
														>
															<div @click="edit(item)">
																<i
																	class="mdi mdi-18px mdi-file-edit-outline"
																></i>
															</div>
														</div>

														<div
															:key="index"
															v-if="type == 'delete'"
															class="col-md-4 col-xl-3 col-lg-4 col-sm-6"
														>
															<div @click="deleteItem(item)">
																<i class="mdi mdi-18px mdi-delete"></i>
															</div>
														</div>
													</template>
												</div>
											</div>

											<!--If actions edit-->
											<div v-else-if="header.type == 'edit'">
												<div
													id="tooltip-button"
													@click="edit(item)"
													class="col-xl-3 col-lg-4 col-sm-6"
												>
													<i class="mdi mdi-18px mdi-file-edit-outline"></i>
												</div>
												<b-tooltip target="tooltip-button" placement="side"
													>Edit</b-tooltip
												>
											</div>

											<div
												v-if="header.type == 'view'"
												@click="view(item)"
												class="col-xl-3 col-lg-4 col-sm-6"
											>
												<i class="mdi mdi-18px mdi-eye"></i>
											</div>
										</template>
									</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
				<!-- end table-responsive-->

				<div class="row">
					<div class="col">
						<div class="float-right">
							<b-pagination
								v-model="currentPage"
								:total-rows="rows"
								:per-page="pagination.perPage"
							></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	export default {
		props: {
			buttonText: String,
			headers: Array,
			items: Array,
			showButton: Boolean,
			title: String,
			subtitle: String,
		},

		data() {
			return {
				currentPage: 1,
				pagination: {
					perPage: 5,
					skip: 0,
				},
			};
		},

		computed: {
			...mapGetters("userModule", ["getUserById"]),

			rows() {
				return this.items.length;
			},

			paginatedItems() {
				let i = this.items.slice(
					this.pagination.skip,
					this.pagination.skip + this.pagination.perPage
				);
				return i;
			},
		},

		methods: {
			paginateNext() {
				this.pagination.skip += this.pagination.perPage;
			},

			paginatePrev() {
				let skip = this.pagination.skip;
				skip -= skip;

				if (skip >= 0) {
					this.pagination.skip = skip;
				}
			},

			addUserClick() {
				this.$emit("add", null);
			},

			getHeaderValue(item, header) {
				if (item) {
					//Gets header options
					const headerValue = header.value;
					const headerType = header.type;

					//Checks if the header value is found in an objet hierarchy
					//e.g: user._id
					if (headerValue.indexOf(".") !== -1) {
						//splits the periods from the string
						const headerValues = headerValue.split(".");
						for (let i = 0; i < headerValues.length; i++) {
							//searches for that value in the item using the object keys given in the header value
							item = item[headerValues[i]];
							if (i == headerValues.length - 1) {
								item = this.checkType(item, headerType);
								return item;
							}
						}
					} else {
						item = item[headerValue];
						item = this.checkType(item, headerType);
						return item;
					}
				}
			},

			//Checks if there are any special formatting for the itme value
			checkType(item, headerType) {
				//displays item as money
				if (headerType == "money") {
					const formatter = new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: "USD",
						// These options are needed to round to whole numbers if that's what you want.
						//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
						//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
					});
					return formatter.format(item);
				} else if (headerType == "array") {
					return item[0].name;
				} else if (headerType == "userId") {
					return this.getUserById(item).name;
				} else if (headerType == "commission") {
					return item + "%";
				} else {
					return item;
				}
			},

			view(item) {
				this.$emit("view", item);
			},

			edit(item) {
				this.$emit("edit", item);
			},

			deleteItem(item) {
				this.$emit("delete", item);
			},
		},

		watch: {
			currentPage(newVal, oldVal) {
				if (newVal > oldVal) {
					this.paginateNext();
				} else {
					this.paginatePrev();
				}
			},
		},
	};
</script>

<style scoped>
	.col-sm-6:hover {
		color: #d8060e;
		cursor: pointer;
	}
</style>
