<template>
  <b-modal
    ref="modal"
    v-model="isOpen"
    :title="modalMode == 'add' ? 'Create New User' : 'Edit User'"
    title-class="font-18"
    body-class="p-4"
    no-close-on-esc
    no-close-on-backdrop 
    @ok="handleSubmission"
    @cancel="handleCancel"
    @close="resetForm"
  >
    <b-alert
      :variant="notification.type"
      class="mt-3"
      v-if="notification.message"
      :show="5"
      dismissible
      @dismissed="notification.message = ''"
      >{{ notification.message }}</b-alert
    >
    <form class="px-3" action="#">
      <div class="form-group">
        <label for="username">Name</label>
        <input
          v-model="$v.formData.name.$model"
          class="form-control"
          :class="{ 'is-invalid': !$v.formData.name.required }"
          type="email"
        />

        <div class="invalid-feedback" v-if="!$v.formData.name.required ">
          <span v-if="!$v.formData.name.required">Name is required</span>
        </div>
      </div>

      <div class="form-group">
        <label for="emailaddress">Email address</label>
        <input
          v-model=" $v.formData.email.$model"
          class="form-control"
          :class="{ 'is-invalid': !$v.formData.email.email || !$v.formData.email.required }"
          type="email"
        />
        <div class="invalid-feedback" v-if=" !$v.formData.email.email || !$v.formData.email.required ">
          <span v-if="!$v.formData.email.required">Email is required</span>
          <span v-if="!$v.formData.email.email">Please enter a valid email address</span>
        </div>
      </div>

      <div class="form-group">
        <label for="role">Role</label>
        <select 
          class="form-control" 
          :class="{ 'is-invalid': !$v.formData.role.required }"
          v-model="$v.formData.role.$model"
          name="role" 
          >
          <option value="ADMIN">Admin</option>
          <option value="BRANCH-MANAGER">Branch Manager</option>
          <option value="EMPLOYEE">Employee</option>
        </select>

        <div class="invalid-feedback" v-if="!$v.formData.email.required ">
          <span v-if="!$v.formData.role.required">User Role is required</span>
        </div>
      </div>

      <div class="form-group">
        <div class="custom-control custom-checkbox">
          <input
            v-model="isActive"
            type="checkbox"
            class="custom-control-input"
            id="customCheck1"
            checked
          />
          <label class="custom-control-label" for="customCheck1"> Is this account active upon creation?</label>
        </div>
      </div>
    </form> 
  </b-modal>
</template>

<script>
  import { required, email } from "vuelidate/lib/validators";
  import { mapActions } from 'vuex';

  export default {

    data() {
      return {
        isOpen: false,
        modalMode: "add",
        formData: {
          name: "",
          email: "",
          role: "",
        },
        isActive: true,

        notification: {
          message: "",
          type: ""
        }
      }
    },

    validations: {
      formData: {
        name: {
          required
        },

        email: {
          required,
          email
        },

        role: {
          required
        }
      }
    },
    
    methods: {
      ...mapActions('userModule', ['createUser', 'updateUser']),

      openModal(item) {
        if(item !=null) {
          this.formData = {...item}
          this.modalMode = "edit"
        } else {
          this.modalMode = "add"
        }
        this.$refs['modal'].show();
      },

      handleCancel() {
        this.resetForm();
      },

      async handleSubmission(event) {
        event.preventDefault();
        this.formData.isActive = this.isActive;
        this.$v.$touch()

        if(!this.$v.$invalid) {
          let result = {}

          if(this.modalMode == "add")
            result = await this.createUser(this.formData);
          else if(this.modalMode == "edit") 
            result = await this.updateUser(this.formData);

          if(result.status == 201 || result.status == 200) {
            if(result.status == 200) {
              this.$bvToast.toast(`User Successfully Edited`, {
                title: `Success!`,
                variant: 'success',
                solid: true
              });
            } else {
              this.$bvToast.toast(`User Successfully Created and Added`, {
                title: `Success!`,
                variant: 'success',
                solid: true
              });
            }
            this.resetForm();
            this.$nextTick(() => {
              this.$refs['modal'].hide()
            })
          } else {
            this.notification.message = result.message;
            this.notification.type = "danger"
          }
        } else {
          this.notification.message = "Please complete all the fields before adding"
          this.notification.type = "danger"
        }
      },

      resetForm() {
        this.formData = {
          name: "",
          email: "",
          role: "",
          isActive: true
        }
        this.notification.message = ''
      }
    }
  }
</script>

<style scoped>

</style>